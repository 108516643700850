import React from 'react';
import { AnimatedBusinessIntelligence } from 'components/animated-business-intelligence';
import { AnimatedDataScience } from 'components/animated-data-science';
import { AnimatedMobileDevelopment } from 'components/animated-mobile-development';
import { AnimatedWebDevelopment } from 'components/animated-web-development';
import { PATHS } from 'constants/paths';

export const OUR_SERVICES = [
    {
        id: 'mobile-development',
        title: 'services.ourServices.mobileTitle',
        description: 'services.ourServices.mobileDescription',
        url: `${PATHS.SERVICES}/${PATHS.MOBILE_DEVELOPMENT}`,
        img: <AnimatedMobileDevelopment />,
    },
    {
        id: 'web-development',
        title: 'services.ourServices.webTitle',
        description: 'services.ourServices.webDescription',
        url: `${PATHS.SERVICES}/${PATHS.WEB_DEVELOPMENT}`,
        isReversed: true,
        img: <AnimatedWebDevelopment />,
    },
    {
        id: 'date-science',
        title: 'services.ourServices.dataScienceTitle',
        description: 'services.ourServices.dataScienceDescription',
        url: `${PATHS.SERVICES}/${PATHS.DATA_SCIENCE}`,
        img: <AnimatedDataScience />,
    },
    {
        id: 'business',
        title: 'services.ourServices.businessTitle',
        description: 'services.ourServices.businessDescription',
        url: `${PATHS.SERVICES}/${PATHS.BUSINESS_INTELLIGENCE}`,
        img: <AnimatedBusinessIntelligence />,
        isReversed: true,
    },
];
