import React from 'react';
import { OurServicesSection } from 'modules/our-services-section';

import { OUR_SERVICES } from './constant';

export const ServicesSection = () => {
    return (
        <OurServicesSection
            title="services.ourServices.header"
            paragraph="services.ourServices.subtitle"
            data={OUR_SERVICES}
        />
    );
};
