import React from 'react';
import { ServicesView } from 'views/services';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import blogMessages from 'constants/translations/blog.json';
import messages from 'constants/translations/index.json';
import servicesMessages from 'constants/translations/services/index.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const ServicesPage = ({ location, data }) => {
    const blogPosts = data?.allContentfulBlogPost?.edges || [];
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...servicesMessages, ...blogMessages }}
        >
            <ServicesView {...{ blogPosts, faqData }} />
        </Layout>
    );
};

ServicesPage.propTypes = {
    location: object.isRequired,
};

export default ServicesPage;

export const Head = () => <SEO tags={TAGS.SERVICES} />;

export const pageQuery = graphql`
    query EnIndexPageQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            name: { nin: ["press-release", "nft-reality"] }
                        }
                    }
                }
            }
            sort: { date: DESC }
            limit: 3
        ) {
            edges {
                node {
                    ...BlogPostFragment
                }
            }
        }
        allContentfulFaq(filter: { slug: { eq: "services" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
